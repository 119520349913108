import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { Container, Typography, Box, TextField, Card, CardContent, CardActions, IconButton, CircularProgress, Button,Dialog, DialogTitle,DialogContent  } from '@mui/material';
import { Save as SaveIcon, Mic as MicIcon, Stop as StopIcon, Description as DescriptionIcon, Mood as MoodIcon } from '@mui/icons-material';
import { db } from '../firebase';
import { collection, addDoc, orderBy } from 'firebase/firestore';
import WaveformVisualizer from './WaveformVisualizer';
import TranscriptionList from './TranscriptionList';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; // Add downward arrow
import './AudioRecorder.css';
import { query, where, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import { subDays, format } from 'date-fns';
import { Divider } from '@mui/material';

import { History as HistoryIcon } from '@mui/icons-material';

const AudioRecorder = ({ userId, userName, onTranscription, hasPaid, userEmnail}) => {
  const EmotionTrendChart = lazy(() => import('./EmotionTrendChart.js'));
    const [recording, setRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
   
    const [loading, setLoading] = useState(false);
    const [transcription, setTranscription] = useState('');
    const [transcriptionId, setTranscriptionId] = useState(null);
    const [savedTranscriptionsCount, setSavedTranscriptionsCount] = useState(0);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const transcriptionListRef = useRef(null);
    const [emotions, setEmotions] = useState(null);
    const [dominantEmotion, setDominantEmotion] = useState('');
    const [selfReflection, setSelfReflection] = useState('');
    const [transcriptionLoading, setTranscriptionLoading] = useState(false);
  const [assessmentLoading, setAssessmentLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180); // 3 minutes in seconds
const [timerActive, setTimerActive] = useState(false);
const [showTranscriptionsPopup, setShowTranscriptionsPopup] = useState(false);

 

  const [showTranscription, setShowTranscription] = useState(false);
  const [showEmotions, setShowEmotions] = useState(false);


  useEffect(() => {
    let interval = null;
    if (timerActive && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft(timeLeft => timeLeft - 1);
      }, 1000);
    } else if (timerActive && timeLeft === 0) {
      clearInterval(interval);
      stopRecording();
    }
    return () => clearInterval(interval);
  }, [timerActive, timeLeft]);


    
    useEffect(() => {
        fetchSavedTranscriptionsCount();
      }, [userId]);

      const fetchSavedTranscriptionsCount = async () => {
        try {
          const q = query(collection(db, "transcriptions"), where("userId", "==", userId));
          const querySnapshot = await getDocs(q);
          setSavedTranscriptionsCount(querySnapshot.size);
        } catch (error) {
          console.error("Error fetching saved transcriptions count:", error);
        }
      };

    useEffect(() => {
        if (recording) {
            visualize();
        } else {
            stopVisualizing();
        }
    }, [recording]);  

    useEffect(() => {
      return () => {
        if (mediaRecorder && mediaRecorder.stream) {
          mediaRecorder.stream.getTracks().forEach(track => track.stop());
        }
      };
    }, [mediaRecorder]);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const analyser = audioContext.createAnalyser();
            const source = audioContext.createMediaStreamSource(stream);
            source.connect(analyser);
            analyser.fftSize = 2048;

            audioContextRef.current = audioContext;
            analyserRef.current = analyser;

            const recorder = new MediaRecorder(stream);
            let audioChunks = [];

            recorder.ondataavailable = e => {
                audioChunks.push(e.data);
            };

            recorder.onstop = async () => {
                const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                const reader = new FileReader();
                reader.readAsDataURL(audioBlob);
                reader.onloadend = async () => {
                    const base64String = reader.result.split(',')[1]; // Remove the data URL prefix
                    setLoading(true);
                    await sendAudioToServer(base64String);
                    setLoading(false);
                };
                setRecording(false);
                audioContext.close();
            };

            recorder.start();
            setRecording(true);
            setMediaRecorder(recorder);
            setTimeLeft(180); // Reset timer to 3 minutes
            setTimerActive(true);
        } catch (err) {
            console.error('Error accessing audio devices.', err);
        }
    };

    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const stopRecording = () => {
      if (mediaRecorder) {
        mediaRecorder.stop();
        if (mediaRecorder.stream) {
          mediaRecorder.stream.getTracks().forEach(track => track.stop());
        }
        setMediaRecorder(null);
      }
      setTimerActive(false);
      setTimeLeft(180); // Reset timer
    };

    const getEmotionColor = (emotion) => {
        if (!emotion) return '#757575'; // Default color for undefined emotions
        
        const colors = {
          happiness: '#4CAF50',
          sadness: '#2196F3',
          anxiety: '#FFC107',
          gratitude: '#9C27B0',
          anger: '#F44336',
          fear: '#FF5722',
          hope: '#8BC34A',
          confidence: '#00BCD4',
          neutral: '#9E9E9E' // Adding a color for neutral
        };
        return colors[emotion.toLowerCase()] || '#757575';
      };

    // const sendAudioToServer = async (audioBase64) => {
    //     try {
    //         const response = await fetch('https://us-central1-noted-e6941.cloudfunctions.net/transcribeAudio', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ audioBase64, userId })
    //         });

    //         if (!response.ok) {
    //             throw new Error('Failed to upload and transcribe audio.');
    //         }

    //         const data = await response.json();
    //         setTranscription(data.transcription);
    //         setTranscriptionId(null); // Reset transcriptionId when new transcription is done
    //         onTranscription(data.transcription);
    //     } catch (error) {
    //         console.error('Error sending audio file:', error);
    //     }
    // };
    const sendAudioToServer = async (audioBase64) => {
      try {
        setLoading(true);
        setTranscriptionLoading(true);
    
        const functions = getFunctions();
        const transcribeAudio = httpsCallable(functions, 'transcribeAudio');
        const analyzeEmotion = httpsCallable(functions, 'analyzeEmotion');
    
        // First, get the transcription
        const transcriptionResult = await transcribeAudio({ audioBase64 });
        setTranscription(transcriptionResult.data.transcription);
        setTranscriptionLoading(false);
        onTranscription(transcriptionResult.data.transcription);
    
        // Then, analyze emotions
        setAssessmentLoading(true);
        const emotionResult = await analyzeEmotion({ transcription: transcriptionResult.data.transcription });
        setEmotions(emotionResult.data.emotions);
        setDominantEmotion(emotionResult.data.dominantEmotion);
        setSelfReflection(emotionResult.data.selfReflection);
        setAssessmentLoading(false);
    
        setTranscriptionId(null);
      } catch (error) {
        console.error('Error processing audio:', error);
      } finally {
        setLoading(false);
        setTranscriptionLoading(false);
        setAssessmentLoading(false);
      }
    };

    // const sendAudioToServer = async (audioBase64) => {
    //     try {
    //        setLoading(true);
    //       setTranscriptionLoading(true);
    //       setAssessmentLoading(true);
    //       const functions = getFunctions();
    //       const processAudio = httpsCallable(functions, 'processAudio');
          
    //       const result = await processAudio({ audioBase64, userId });
    //       if (result.data.transcription) {
    //         setTranscription(result.data.transcription);
    //         setTranscriptionLoading(false);
    //         onTranscription(result.data.transcription);
    //       }
          
    //       if (result.data.emotions) {
    //         setEmotions(result.data.emotions);
    //         setDominantEmotion(result.data.dominantEmotion);
    //         setSelfReflection(result.data.selfReflection);
    //         setAssessmentLoading(false);
    //       }
          
    //     //   setTranscription(result.data.transcription);
    //     //   setEmotions(result.data.emotions);
    //     //   setDominantEmotion(result.data.dominantEmotion);
    //     //   setSelfReflection(result.data.selfReflection);
          
    //       setTranscriptionId(null); // Reset transcriptionId when new transcription is done
    //       //onTranscription(result.data.transcription);
    //     } catch (error) {
    //         console.error('Error processing audio:', error);
    //         setTranscriptionLoading(false);
    //         setAssessmentLoading(false);
    //     } finally {
    //       setLoading(false);
    //     }
    //   };


    // const saveTranscription = async () => {
    //     if (!hasPaid && savedTranscriptionsCount >= 6) {
    //         console.log("Upgrade required to save more transcriptions");
    //         return;
    //       }
    //     try {
    //         console.log("Attempting to save transcription...", { userId, userName, transcription });
    //         const docRef = await addDoc(collection(db, "transcriptions"), {
    //             userId,
    //             userName,
    //             transcription,
    //             timestamp: new Date(),
    //             public: false,
    //         });
    //         setTranscriptionId(docRef.id);
    //         console.log("Document written with ID: ", docRef.id);
    //         setSavedTranscriptionsCount(prev => prev + 1);

    //         // Clear current transcription
    //         setTranscription('');
    //         setTranscriptionId(null);

    //         // Refresh transcription list
    //         transcriptionListRef.current.refreshTranscriptions();
    //     } catch (error) {
    //         console.error("Error adding document: ", error);
    //     }
    // };

    const saveTranscription = async () => {
        if (!hasPaid && savedTranscriptionsCount >= 6) {
          console.log("Upgrade required to save more transcriptions");
          return;
        }
      
        try {
          console.log("Attempting to save transcription...", { userId, userName, transcription, emotions, dominantEmotion, selfReflection });
          const docRef = await addDoc(collection(db, "transcriptions"), {
            userId,
            userName,
            transcription,
            emotions,
            dominantEmotion,
            selfReflection,
            timestamp: new Date(),
            public: false,
          });
          setTranscriptionId(docRef.id);
          console.log("Document written with ID: ", docRef.id);
          setSavedTranscriptionsCount(prev => prev + 1);
          setTranscription('');
          setEmotions(null);
          setDominantEmotion('');
          setSelfReflection('');
          setTranscriptionId(null);
          transcriptionListRef.current.refreshTranscriptions();
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      };

    const handleUpgrade = () => {
        // Implement the logic to redirect the user to the upgrade/payment page
        console.log("Redirecting to upgrade page");
      };

    const visualize = () => {
        console.log('Starting visualization');
        const analyser = analyserRef.current;
        if (!analyser) return; // Ensure the analyser is available
        // Additional visualization logic for fireworks can go here
    };

    const stopVisualizing = () => {
        console.log('Stopping visualization');
    };

    return (
        <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Box mt={5} textAlign="center">



      {!recording && !transcription && !loading && (
        <Suspense fallback={<CircularProgress />}>
          <EmotionTrendChart userId={userId} />
        </Suspense>
      )}


                {/* Show the transcription card if not recording or not loading */}
                {!recording && transcription && (
                    <Card variant="outlined" style={{ marginBottom: '20px' }}>
          <CardContent>
            {transcriptionLoading ? (
              <Typography>Transcribing...</Typography>
            ) : (
              <>
                <Typography variant="h6">Self-Reflection</Typography>
                {assessmentLoading ? (
                  <Typography>Assessing...</Typography>
                ) : (
                  <>
                    <Typography>{selfReflection}</Typography>
                    <Typography> ----</Typography>
                    
                    <Typography>Emotion: {dominantEmotion}</Typography>
                  </>
                )}
              </>
            )}
          </CardContent>
                        <CardActions>
                        <IconButton onClick={() => setShowTranscription(!showTranscription)}>
              <DescriptionIcon />
            </IconButton>
            <IconButton onClick={() => setShowEmotions(!showEmotions)}>
                <MoodIcon style={{ color: getEmotionColor(dominantEmotion) }} />
              </IconButton>

            {/* <IconButton onClick={() => setShowEmotions(!showEmotions)} disabled={assessmentLoading}>
              <MoodIcon  />
            </IconButton> */}

<Box flexGrow={1} />

                            <IconButton color="primary" onClick={saveTranscription} disabled={!transcription || !hasPaid && savedTranscriptionsCount >= 6}>
                                <SaveIcon />
                            </IconButton>

                            {!hasPaid && savedTranscriptionsCount >= 6 && (
  <Box display="flex" alignItems="center" flexDirection="column">
    <Button 
      variant="contained" 
      color="primary" 
      onClick={() => {
        const stripeLink = `https://buy.stripe.com/fZeaF31eK5KhcHCbII?prefilled_email=${encodeURIComponent(userEmnail)}&client_reference_id=${encodeURIComponent(userId)}`;
        window.location.href = stripeLink;
      }}
    >
      Upgrade
    </Button>
    <Typography variant="body2" style={{ marginRight: '1px' }}>
     for unlimited journaling
    </Typography>
  </Box>
)}

                        </CardActions>
                    </Card>
                )}

<Dialog
  open={showTranscriptionsPopup}
  onClose={() => setShowTranscriptionsPopup(false)}
  fullWidth
  maxWidth="md"
>
  <DialogTitle>Previous Transcriptions</DialogTitle>
  <DialogContent>
    <TranscriptionList userId={userId} />
  </DialogContent>
</Dialog>

{showTranscription && (
        <Dialog open={showTranscription} onClose={() => setShowTranscription(false)}>
          <DialogTitle>Transcription</DialogTitle>
          <DialogContent>
            <Typography>{transcription}</Typography>
          </DialogContent>
        </Dialog>
      )}
      {showEmotions && (
        <Dialog open={showEmotions} onClose={() => setShowEmotions(false)}>
          <DialogTitle>Emotion Analysis</DialogTitle>
          <DialogContent>
            {emotions && Object.entries(emotions).map(([emotion, score]) => (
                      <Typography key={emotion} style={{ color: getEmotionColor(emotion) }}>
          {emotion}: {score}
        </Typography>
            ))}
          </DialogContent>
        </Dialog>
      )}

                {/* Show loading indicator when waiting for transcription */}
                {loading && <CircularProgress />}

                {/* Show waveform when recording */}
                {recording && <WaveformVisualizer analyser={analyserRef.current} />}

                {/* Show Mic/Stop button */}
                <IconButton
                    onClick={recording ? stopRecording : startRecording}
                    disabled={loading}
                    sx={{
                        backgroundColor: "primary.main",
                        borderRadius: "50%",
                        padding: "20px",
                        fontSize: 40,
                        color: recording ? "black" : "inherit",
                        position: 'fixed',
                        bottom: '20px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }}
                >
                    {recording ? <StopIcon style={{color: 'black'}}  fontSize="large"/> : <MicIcon fontSize="large" />}
                    </IconButton>

                


                <TranscriptionList ref={transcriptionListRef} userId={userId} />
            </Box>
            {/* <Box position="fixed" bottom={16} left={16} zIndex={1000}>
      <IconButton 
        onClick={() => setShowTranscriptionsPopup(true)}
        sx={{ 
          backgroundColor: 'background.paper',
          boxShadow: 3,
          '&:hover': { backgroundColor: 'background.paper' }
        }}
      >
        <HistoryIcon />
      </IconButton>
    </Box> */}

    {recording && (
      
    <Typography variant="body2" style={{ marginTop: '5px' }}>
    
      <Box position="fixed" bottom={16} left={80} zIndex={1000}>
      <IconButton 
        onClick={() => setShowTranscriptionsPopup(true)}
        sx={{ 
          backgroundColor: 'background.paper',
          boxShadow: 3,
          '&:hover': { backgroundColor: 'background.paper' }
        }}
      >
  {formatTime(timeLeft)}
      </IconButton>
    </Box>
    </Typography>
  )}




        </Container>
    );
};

export default AudioRecorder;
